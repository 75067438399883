(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Cookies"), require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["Cookies", "jQuery"], factory);
	else if(typeof exports === 'object')
		exports["Member"] = factory(require("Cookies"), require("jQuery"));
	else
		root["Member"] = factory(root["Cookies"], root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__) {
return 