import $ from 'jquery';
import Cookies from 'js-cookie';

export class Member {
  constructor(opts) {
    this.apiKey = opts.apiKey || null;
    this.cookieName = opts.cookieName || 'member';
    this.cookieValue = opts.cookieValue || 'member';
    this.cookieValid = opts.cookieValid || 7;
  }

  /**
   * Method สำหรับเช็ก member ตามชื่อที่กำหนดหรือตามค่า default
   * @param {string} name (optional)
   */
  isMember( name ) {
    var cookieName = name || this.cookieName;
    return Cookies.get(cookieName);
  }

  /**
   * Method สำหรับเรียกดูค่าของ member ที่เก็บไว้ใน cookie
   * @param {string} name (optional)
   */
  getMember( name ) {
    var cookieName = name || this.cookieName;
    return Cookies.get(cookieName);
  }

  /**
   * Method ใช้สำหรับเซ็ตค่า member ลงในเบราว์เซอร์ในรูปแบบ cookie
   * @param {string} name (optional) ชื่อ cookie
   * @param {int} value (optional) ค่าใน cookie
   */
  setMember( name, value ) {
    var cookieName = name || this.cookieName;
    var cookieValue = value || this.cookieValue;
    Cookies.set(cookieName, cookieValue, {
      expires: parseFloat(this.cookieValid),
    });
  }

  /**
   * Method ใช้สำหรับลบ cookie ตามชื่อที่กำหนดหรือตามค่า default
   * @param {string} name (optional)
   */
  delMember( name ) {
    var cookieName = name || this.cookieName;
    Cookies.remove(cookieName);
  }

  /**
   * Method สำหรับ submit ข้อมูลไปยัง Server ของ Travelanium
   * @param {object} data Post data in JSON format
   */
  submit( data ) {
    var DATA = {
      formKey: this.apiKey,
      name: '',
      phone: '',
      email: '',
      countryCode: '',
      countryName: '',
      message: '',
      ...data
    }

    var post = $.post({
      url: 'https://hotelservices.travelanium.net/crs-customer-form-rs/restresources/CRSCustomerFormDataService',
      data: JSON.stringify(DATA),
      contentType: 'application/json'
    });

    return post;
  }
}

export default Member;